import React, { ReactElement } from 'react';

import classNames from 'classnames';

import { LOADING_CLASS } from '../../utils/constants';
import * as Styled from './Loading.styles';
import { ILoadingProps } from './Loading.types';

const Loading = ({ className = '', size = 'normal', ...rest }: ILoadingProps): ReactElement => (
  <Styled.Loading aria-label="Loading spinner" className={classNames(LOADING_CLASS, className)} size={size} {...rest} />
);

export default Loading;

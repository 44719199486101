export const DARK_THEME = 'dark';
export const LIGHT_THEME = 'light';

export const FONT_WEIGHT_BOLD = 700;
export const FONT_WEIGHT_DEMI = 600;
export const FONT_WEIGHT_NORMAL = 400;

export const FONT_SIZE_LABEL_SMALL = '10px';
export const FONT_SIZE_LABEL_MEDIUM = '11px';
export const FONT_SIZE_LABEL_LARGE = '11px';
export const FONT_SIZE_LABEL_XLARGE = '14px';

export const NOOP = (): null => null;

export const ACCORDION_CLASS = 'shell-accordion';
export const ACCORDION_PANEL_CLASS = 'shell-accordion-panel';
export const ALERT_CLASS = 'shell-alert';
export const AVATAR_CLASS = 'shell-avatar';
export const AVATAR_WITH_TEXT_CLASS = 'shell-avatar-with-text';
export const BADGE_CLASS = 'shell-badge';
export const BREADCRUMB_CLASS = 'shell-breadcrumb';
export const BUTTON_CLASS = 'shell-button';
export const CARD_CLASS = 'shell-card';
export const CHECKBOX_CLASS = 'shell-checkbox';
export const DATE_PICKER_CLASS = 'shell-date-picker';
export const DROPDOWN_CLASS = 'shell-dropdown';
export const DROPDOWN_CONTAINER_CLASS = `${DROPDOWN_CLASS}-container`;
export const DROPDOWN_CONTAINER_OVERLAY_CLASS = `${DROPDOWN_CONTAINER_CLASS}-overlay`;
export const FORM_FIELD_CLASS = 'shell-form-field';
export const ICON_CLASS = 'shell-icon';
export const LOADING_CLASS = 'shell-loading';
export const MENU_CLASS = 'shell-menu';
export const MODAL_CLASS = 'shell-modal';
export const MODAL_CONTAINER_CLASS = `${MODAL_CLASS}-container`;
export const NAV_BAR_CLASS = 'shell-nav-bar';
export const PAGINATION_CLASS = 'shell-pagination';
export const RADIO_BUTTON_CLASS = 'shell-radio-button';
export const SELECT_CLASS = 'shell-select';
export const SELECT_CONTAINER_CLASS = `${SELECT_CLASS}-container`;
export const SELECT_CONTAINER_ERROR_CLASS = `${SELECT_CONTAINER_CLASS}-error`;
export const SLIDER_CLASS = 'shell-slider';
export const STAT_CLASS = 'shell-stat';
export const STEPPER_CLASS = 'shell-stepper';
export const SWITCH_CLASS = 'shell-switch';
export const TABLE_CLASS = 'shell-table';
export const TABS_CLASS = 'shell-tabs';
export const TAG_CLASS = 'shell-tag';
export const TEXT_CLASS = 'shell-text';
export const TEXT_AREA_CLASS = 'shell-text-area';
export const TEXT_INPUT_CLASS = 'shell-text-input';
export const TEXT_SECTION_CLASS = 'shell-text-section';
export const TOGGLE_CLASS = 'shell-toggle';
export const TOGGLE_SWITCH_CLASS = `${TOGGLE_CLASS}-switch`;
export const TOOLTIP_CLASS = 'shell-tooltip';
export const TOOLTIP_CONTAINER_CLASS = `${TOOLTIP_CLASS}-container`;
export const TREE_CLASS = 'shell-tree';
export const TREE_CHECKBOX_CLASS = `${TREE_CLASS}-checkbox-wrapper`;
export const TREE_CHECKBOX_CHECKED_CLASS = `${TREE_CHECKBOX_CLASS}-checked`;
export const TREE_CHECKBOX_UNCHECKED_CLASS = `${TREE_CHECKBOX_CLASS}-unchecked`;
export const TREE_CHECKBOX_INDETERMINATE_CLASS = `${TREE_CHECKBOX_CLASS}-indeterminate`;

export const ASC = 'ascend';
export const DESC = 'descend';

import styled, { css } from 'styled-components';

import { getBorder, getPositionLeft, getPositionRight, getPositionTop, getSize, rotate } from './helpers/styleHelpers';
import { ILoadingProps } from './Loading.types';

const Loading = styled.div<ILoadingProps>(
  ({ theme, size }) =>
    size &&
    css`
      height: ${getSize(size)}px;
      width: ${getSize(size)}px;
      border-radius: 50%;
      border: ${getBorder(size)}px solid ${theme.borders.subtle};
      border-top: ${getBorder(size)}px solid ${theme.accent.primary.strong};
      position: relative;
      animation: ${rotate} 0.8s linear infinite;

      &:after,
      &:before {
        content: '';
        height: ${getBorder(size)}px;
        width: ${getBorder(size)}px;
        border-radius: 50%;
        background-color: ${theme.accent.primary.strong};
        position: absolute;
        top: ${getPositionTop(size)}px;
        overflow: hidden;
      }

      &:before {
        left: ${getPositionLeft(size)}px;
      }

      &:after {
        right: ${getPositionRight(size)}px;
      }
    `,
);

export { Loading };

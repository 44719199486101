import { keyframes } from 'styled-components';

import { loadingConsts } from '../Loading.consts';
import { LoadingSize } from '../Loading.types';

export const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

export const getSize = (size: LoadingSize) => loadingConsts[size].size;
export const getBorder = (size: LoadingSize) => loadingConsts[size].border;
export const getPositionLeft = (size: LoadingSize) => loadingConsts[size].left;
export const getPositionTop = (size: LoadingSize) => loadingConsts[size].top;
export const getPositionRight = (size: LoadingSize) => loadingConsts[size].right;

import { LoadingSize } from './Loading.types';

type LoadingConstsType = {
  [size in LoadingSize]: {
    size: number;
    border: number;
    top: number;
    left: number;
    right: number;
  };
};

export const loadingConsts: LoadingConstsType = {
  large: {
    size: 40,
    border: 4,
    top: 2,
    left: 3,
    right: 2.6,
  },
  normal: {
    size: 30,
    border: 3,
    top: 1.5,
    left: 2,
    right: 2,
  },
  small: {
    size: 20,
    border: 2,
    top: 1,
    left: 1,
    right: 1,
  },
  extraSmall: {
    size: 13,
    border: 2,
    top: 0,
    left: 0,
    right: 0,
  },
};
